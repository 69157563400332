<template>
  <div class="modal fade" id="navStoreModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="storeModallabel" aria-hidden="true" ref="storeModalRef" style="z-index: 9999;" v-if="this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0">      
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="filterLabel">{{$t('Select')}} {{ $auth.setting && $auth.setting.restaurant_name }}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="oncloseModle"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="hme-res-itm">
                  <div class="resturant-item-main1" v-if="stores && stores.length > 0 && $auth && $auth.getVendorId() > 0">
                    <item v-for="item in stores" :key="item.restaurant_id" :item="item" 
                      :isPickupDelivery="isPickupDelivery"
                      :isSchedule="$auth.setting.is_enable_schedule_order_after_business_hours"
                      :webVendorLayout="1"
                      components="navbar" 
                      v-on:closedModal="oncloseModle"
                      >
                    </item>                      
                  </div>
                  <div v-else class="modal-body text-center"><p> <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span></p></div>
                </div>
              </div>
            </div>
          </div>
        </div>          
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Item from "@/components/Item.vue";
export default {
  components: {Item},
  props: [],
  data() {
    return {
      stores:[]
    };
  },
  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),    
  },
  mounted() {    
    window.$("#navStoreModal").on('show.bs.modal',function(){});
    window.$("#navStoreModal").modal("show");
    this.getMultipleStore();
  },
  methods:{
    ...mapActions("restaurant", ["getRestaurants"]),

    getMultipleStore(){
      let restData = {
        cityname: localStorage.getItem("city"),
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude"),
        is_langauge: this.$store.state.lang.locale,
        vendor_id: this.$auth.getVendorId(),
        delivery_type_time_slots: this.isPickupDelivery,
        state: localStorage.getItem("state"),
        total_record: 1000,
        date: "",
        cuisine_id: '',
        skip: 0,
        starttime: "",
        sort_type: '',
        user_id: "",
        filter_type: "",
        cost_range: "",
      };
      this.getRestaurants(restData).then((data) => {          
        if (data.code == 200) {
          this.stores = data.Result;            
        }
      })
    },

    oncloseModle() {      
      this.$emit("storeModal");
    }
  }
}
</script>
<style>
.modal-body .resturant-item-main1 .rest-item-list1{
    width: 100%;
  }
  </style>