<script>
import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",
  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: ['directions_changed'],

  mappedProps: {},

  props: {
    origin: { type: [Object, Array] },
    destination: { type: [Object, Array] },
    travelMode: { type: String },
  },
  
  afterCreate(directionsRenderer) {    
    let directionsService = new window.google.maps.DirectionsService();
    let { origin, destination, travelMode } = this;
        if (!origin || !destination || !travelMode) return;
        directionsService.route({origin,destination,travelMode},
          (response, status) => {            
            if (status !== "OK") return;            
            var lineSymbol = {
                path: google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                scale: 3
            };                
            directionsRenderer.setOptions({
                markerOptions:{
                    visible:false,
                },
                polylineOptions:{
                    strokeColor:'red',                    
                    strokeOpacity: 0,
                    fillOpacity: 0,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px'
                    }],
                },
            });
            directionsRenderer.setDirections(response);            
          }
        );
    this.$watch(
      () => [this.origin, this.destination, this.travelMode],
      () => {
        let { origin, destination, travelMode } = this;
        if (!origin || !destination || !travelMode) return;
        directionsService.route({origin,destination,travelMode},
          (response, status) => {
            if (status !== "OK") return;
            //debugger
            var lineSymbol = {
              path: google.maps.SymbolPath.CIRCLE,
              fillOpacity: 1,
              scale: 3
            };
            directionsRenderer.setOptions({
              markerOptions:{visible:false},
              polylineOptions:{
                strokeColor:'red',
                strokeOpacity: 0,
                fillOpacity: 0,
                icons: [{
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '10px'
                }],
              },
            });
            directionsRenderer.setDirections(response);
          }
        );
      }
    );
  },
});
</script>